import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/SchoolProgram/Valedictory/1.png';
import p2 from 'assests/Photos/SchoolProgram/Valedictory/2.png';
import p3 from 'assests/Photos/SchoolProgram/Valedictory/3.png';
import p4 from 'assests/Photos/SchoolProgram/Valedictory/4.png';
import p5 from 'assests/Photos/SchoolProgram/Valedictory/5.png';
import p6 from 'assests/Photos/SchoolProgram/Valedictory/6.png';
import p7 from 'assests/Photos/SchoolProgram/Valedictory/7.png';
import p8 from 'assests/Photos/SchoolProgram/Valedictory/8.png';





import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/SchoolProgram/components/SidebarArticles/SidebarArticles';
// import { Roadsafety } from 'views';

const ValeDictory = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source: p2,
      rows: 1,
      cols: 1,
    },
    {
        src: p3,
        source: p3,
        rows: 1,
        cols: 1,
      },
      {
        src: p4,
        source: p4,
        rows: 1,
        cols: 1,
      },
      {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      {
        src: p7,
        source: p7,
        rows: 1,
        cols: 1,
      },
      {
        src: p8,
        source: p8,
        rows: 1,
        cols: 1,
      },


    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Valedictory Ceremony 
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
        2023-22
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Valedictory Ceremony for the year 2022-23 was held on 13 January 2023. The student council passed on the baton to the next rung of leaders. The programme started with March Past as the student council owed their final salute to their alma mater followed by the Captains handing over the flags to their Vice captains with the belief “So others will follow, that’s how I want to lead and hope that this exuberance will never ever cede, I want to be a role model; an example to set”.
<br></br>
The leaders were overwhelmed that during their tenure they were polished to be better humans by enhancing their skills, physically and mentally. This position would always be a milestone and considered as a major achievement. This programme was presided by the Principals and they congratulated the Cabinet and the outgoing students for their endeavours. 

        </Typography>
      </Box>
      <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
  
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ValeDictory;